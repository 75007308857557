import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import SocialIcon from './SocialIcon'
import { getColorHex, getContrastingTextForBackground } from 'utils'

export const RateList = ({ rate_card }) => {
  const supported_platforms = ['instagram', 'tiktok', 'youtube']

  return (
    <ul className="list-group">
      {rate_card.map((rateItem, index) => (
        <li key={index} className="list-group-item">
          <div className="d-flex justify-content-between">
            <div className="d-flex" style={{ fontSize: '1.2em' }}>
              {supported_platforms.includes(rateItem.platform) && (
                <p className="px-2">
                  <SocialIcon className="me-2" platform={rateItem.platform} />
                </p>
              )}
              <p>{rateItem.description}</p>
            </div>
            <div>
              <p style={{ fontSize: '1.3em' }}>{rateItem.rate}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

const RateCard = ({ rate_card, accentColor, buttonText = 'Show Rates' }) => {
  const [show, setShow] = useState(false)

  const colorHex = getColorHex(accentColor)
  const buttonTextColor = getContrastingTextForBackground(colorHex)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <button
        style={{
          backgroundColor: colorHex,
          color: buttonTextColor,
          fontSize: '1.2em',
        }}
        className="btn"
        onClick={handleShow}
      >
        {buttonText}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Rates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RateList rate_card={rate_card} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RateCard
